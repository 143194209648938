import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const ExitDevicesVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Exit Devices Videos Page' 
      description='A list of Command Access Exit Devices videos.' 
    />

    <VideosSectionUpdate 
      title="Exit Devices"
      category="Exit Devices"
    />
  </MainContainer>
)

export default ExitDevicesVideosPage